/* eslint-disable eqeqeq, react-hooks/exhaustive-deps, default-case */
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
    getContextRoot,
    getApiRoot,
    getLanguageValue,
    navigateToPath,
    isVisaReady,
    isInactiveField,
    googleRecaptcha
} from "../../common/functions";
import lodashGet from 'lodash/get';
import CONSTANTS from '../../common/constants';
import { CSS_COLORS } from '../../common/cssColors';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import MuiAlert from "@material-ui/lab/Alert";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Sync';
import Loader from "../Loader";
import axios from 'axios';



const token = "SSp@LHp*$bp6kL";
axios.defaults.headers.common['Authorization'] = `Bearer SSp@LHp*$bp6kL`;
axios.defaults.headers.common['x-access-token'] = `SSp@LHp*$bp6kL`;
const headers_post= {
              headers: {
              'Authorization': `Basic ${token}`,
              'Access-Control-Allow-Origin' : '*'
              }
              }



              function Alert(props) {

                  return <MuiAlert elevation={6}
                      variant="filled" {...props} />;
              }

function RegisterStage7Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;
    const percentage = 52;
    const [ formErrors, setFormErrors ] = useState(false);
    const [ loaders, setLoaders ] = useState({ });
    const [ apiRun, setApiRun ] = useState(false);
    const [ statusX, setStatusX ] = useState("");
    const [ disclaimerChecked, setDisclaimerChecked ] = useState(false);
    let [ fieldErrors, setFieldErrors ] = useState({ });
    console.log(props.languageKey);
    const firstNameField = props.languageKey == 'he' ? 'firstName' : 'firstNameEnglish';
    const lastNameField = props.languageKey == 'he' ? 'lastName' : 'lastNameEnglish';
  //  const navigate = useNavigate();
    async function checkifpayed(data) {

    try {
              //console.log(data);
              //const response1 = await axios.get(`https://alertair.net:8443/`, data);
              //console.log(response1);
              const response = await axios.post(`https://vietnam-visa.govis.ai:8443/api/aitrvl/checkifpayed`, data,headers_post);
              return response;


        } catch (error) {
                console.error(error);
              }
    }


    async function altertopaid(data) {

    try {
              //console.log(data);
              //const response1 = await axios.get(`https://alertair.net:8443/`, data);
              //console.log(response1);
              const response = await axios.post(`https://vietnam-visa.govis.ai:8443/api/aitrvl/altertopaid`, data,headers_post);
              return response;


        } catch (error) {
                console.error(error);
              }
    }



    useEffect(() => {
        actions.setActiveStage(7);
        window.routeChangeTimestamp = new Date().getTime();
        let data = {
         generatedId: (props.individualKey || '').toUpperCase()

        }
        let res;
        setApiRun(true);
        setStatusX(props.applicationStatus)

        if (props.applicationStatus=="unpaid")
        {
            if (props.individualKey.length=="10")
            {
              setApiRun(true);

              checkifpayed(data).then( (response)=> {

                res=response.data;
                console.log(res);
                if (res=="paid")
                {

                  //alert("Thanks for updating the form!")
                  altertopaid(data);
                  setStatusX('paid');

                  setApiRun(false);
                //  setLoaders({ ...loaders, validate: true });
                }
                else
                {
                    setApiRun(false);
                }


              });

            }
            else {
                  setApiRun(false);
            }
          }
          else {
            setApiRun(false);
          }


    }, []);

    return <RegisterStage7Style>
        {apiRun && <Grid item xs={3}  md={3}> <CircularProgressbar value={percentage}  text={`${percentage}%`}  styles={{
    // Customize the root svg element
    root: {},
    // Customize the path, i.e. the "completed progress"
    path: {
      // Path color
      stroke: `rgba(62, 152, 199, ${percentage / 100})`,
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: 'butt',
      // Customize transition animation
      transition: 'stroke-dashoffset 0.5s ease 0s',
      // Rotate the path
      transform: 'rotate(0.25turn)',
      transformOrigin: 'center center',
    },
    // Customize the circle behind the path, i.e. the "total progress"
    trail: {
      // Trail color
      stroke: '#d6d6d6',
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: 'butt',
      // Rotate the trail
      transform: 'rotate(0.25turn)',
      transformOrigin: 'center center',
    },
    // Customize the text
    text: {
      // Text color
      fill: '#f88',
      // Text size
      fontSize: '16px',
    },
    // Customize background - only used when the `background` prop is true
    background: {
      fill: '#3e98c7',
    },
  }} /> </Grid>}
        {!apiRun && <div className={"form-container"}>
            <h1>{getLanguageValue(statusX == 'unpaid' ? 'register-individual.stage7-pay-and-finish-application' : 'register-individual.stage7-application-status')}</h1>
            {statusX == 'unpaid' && <div className="form-information-container">
                <span className="bold-text">{getLanguageValue('register-individual.stage7-pay-and-finish-application-explanation')}</span>
                <br />
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage5-required-fields-with-asterisk')}</span>
            </div>}
            <br />


            <table className="desktop-details-table">
                <thead>
                    <tr>
                        <th>{getLanguageValue('register-individual.stage7-table-header-name')}</th>
                        <th>{getLanguageValue('register-individual.stage7-table-header-date-of-birth')}</th>
                        <th>{getLanguageValue('register-individual.stage7-table-header-application-number')}</th>
                        <th>{getLanguageValue('register-individual.stage7-table-header-passport-number')}</th>
                        <th>{getLanguageValue('register-individual.stage7-table-header-status')}</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{lodashGet(props.stage2Data, firstNameField) + ' ' + lodashGet(props.stage2Data, lastNameField)}</td>
                        <td>{moment(lodashGet(props.stage2Data, 'birthDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}</td>
                        <td>{(props.individualKey || '').toUpperCase()}</td>
                        <td>{lodashGet(props.stage2Data, 'passportNumber')}</td>
                        <td><div className={"status-desktop-container" + (formErrors ? ' with-errors' : '')}>{getStatus()}</div></td>
                        <td className={statusX != 'unpaid' ? 'no-padding' : ''}>{statusX == 'unpaid' && <Button className="update-button" onClick={updateApplication}><EditIcon className="update-icon" />{getLanguageValue('register-individual.stage7-table-action-update')}</Button>}</td>
                        <td><Button className="view-button" onClick={viewApplication}><SearchIcon className="view-icon" />{getLanguageValue('register-individual.stage7-table-action-view')}</Button></td>
                        <td>{props.applicationRejectReasons && Object.keys(props.applicationRejectReasons).length > 0 && <Button className="update-rejected-data-button" onClick={updateRejectedData}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-individual.stage7-table-action-update-rejected-data')}</Button>}</td>
                        <td>
                            {isVisaReady(statusX) && props.visaFilesIdArray.length > 0 && <div className={"download-visa-container"}><div className={"download-visa-files-title-text"}>{getLanguageValue('register-individual.stage7-download-visa-files')}:</div> {getVisaLinks()}</div>}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mobile-details-table">
                <div className="row-item even">
                    <div className="row-name-container">{getLanguageValue('register-individual.stage7-table-header-name')}</div>
                    <div className="row-value-container">{lodashGet(props.stage2Data, firstNameField) + ' ' + lodashGet(props.stage2Data, lastNameField)}</div>
                </div>
                <div className="row-item odd">
                    <div className="row-name-container">{getLanguageValue('register-individual.stage7-table-header-date-of-birth')}</div>
                    <div className="row-value-container">{moment(lodashGet(props.stage2Data, 'birthDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}</div>
                </div>
                <div className="row-item even">
                    <div className="row-name-container">{getLanguageValue('register-individual.stage7-table-header-application-number')}</div>
                    <div className="row-value-container">{(props.individualKey || '').toUpperCase()}</div>
                </div>
                <div className="row-item odd">
                    <div className="row-name-container">{getLanguageValue('register-individual.stage7-table-header-passport-number')}</div>
                    <div className="row-value-container">{lodashGet(props.stage2Data, 'passportNumber')}</div>
                </div>
                <div className="row-item even">
                    <div className="row-name-container">{getLanguageValue('register-individual.stage7-table-header-status')}</div>
                    <div className="row-value-container"><div className={"status-mobile-container" + (formErrors ? ' with-errors' : '')}>{getStatus()}</div></div>
                </div>
                <div className="row-item buttons-container">
                    {statusX == 'unpaid' && <div className="update-button-container"><Button className="update-button" onClick={updateApplication}><EditIcon className="update-icon" />{getLanguageValue('register-individual.stage7-table-action-update')}</Button></div>}
                    <div className={"view-button-container" + (statusX == 'unpaid' ? ' partial-width' : ' full-width')}><Button className="view-button" onClick={viewApplication}><SearchIcon className="view-icon" />{getLanguageValue('register-individual.stage7-table-action-view')}</Button></div>
                    {props.applicationRejectReasons && Object.keys(props.applicationRejectReasons).length && <div className={"update-rejected-data-container"}><Button className="update-rejected-data-button" onClick={updateRejectedData}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-individual.stage7-table-action-update-rejected-data')}</Button></div>}
                    {isVisaReady(statusX) && props.visaFilesIdArray.length > 0 && <div className={"download-files-container"}><div className={"download-visa-files-title-text"}>{getLanguageValue('register-individual.stage7-download-visa-files')}:</div>{getVisaLinks()}</div>}
                </div>
            </div>


            {statusX == 'unpaid' && <div className="ready-to-pay-and-payment-summary-container">
                <div className="ready-to-pay-container">
                    <h2>{getLanguageValue('register-individual.stage7-not-ready-to-pay-title')}</h2>
                    <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-not-ready-to-pay-explanation') }} />
                    <br />
                    <div><span className="payment-due-by-title bold-text">{getLanguageValue('register-individual.stage7-payment-due-by')} </span> <span className="payment-due-by-date bold-text field-warning-color">{moment(props.createDate).add(7, 'days').format(CONSTANTS.DATE_FORMAT_MOMENT)}</span></div>
                    <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-payment-due-by-note') }} />
                </div>
                <div className="payment-summary-container">
                    <h2>{getLanguageValue('register-individual.stage7-payment-summary')}</h2>
                    <div className="payment-summary-table">
                        <div className="payment-summary-row">
                            <div className="payment-summary-name product-type">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-type')}</div>
                                <div>{getLanguageValue('register-individual.stage7-visa-type-' + lodashGet(props.stage2Data, 'visaType'))}</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div>{getLanguageValue('register-individual.stage7-expedite-visa')}</div>}
                                {/*<div>{getLanguageValue('register-individual.stage7-visa-delivery-type-' + lodashGet(props.stage2Data, 'deliveryType'))}</div>*/}
                            </div>
                            <div className="payment-summary-type product-count">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-count')}</div>
                                {/*<div>1</div>*/}
                                <div>1</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div>{lodashGet(props.stage2Data, 'visaExpeditedFlag') ? 1 : 0}</div>}

                            </div>
                            <div className="payment-summary-value product-price">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-price')}</div>
                                <div>{CONSTANTS.DOLLAR}{getPrice('visaType')}</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div>{CONSTANTS.DOLLAR}{getPrice('expedite')}</div>}
                                {/*<div>{CONSTANTS.DOLLAR}{getPrice('deliveryType')}</div>*/}
                            </div>
                        </div>
                        <hr/>
                        <div className="payment-summary-row">
                            <div className="payment-summary-name">{getLanguageValue('register-individual.stage7-total-fee')}</div>
                            <div className="payment-summary-value total-price">{CONSTANTS.DOLLAR}{getTotalPrice()}</div>
                        </div>
                        <hr/>
                        <div className="disclaimer-container">
                            <FormControlLabel
                                className={"disclaimer-component"}
                                classes={{ label: 'disclaimer-label' + (fieldErrors.disclaimer ? ' field-warning-color' : '') }}
                                control={
                                    <Checkbox
                                        className={"disclaimer-checkbox-component" + (fieldErrors.disclaimer ? ' field-warning-color field-error' : '')}
                                        checked={disclaimerChecked}
                                        onChange={(e) => { setDisclaimerChecked(e.target.checked); setFieldErrors({ ...fieldErrors, disclaimer: false }); }}
                                        name="disclaimer"
                                        color="primary"
                                    />
                                }
                                label={getLanguageValue('register-individual.stage7-disclaimer')}
                            />
                            {fieldErrors.disclaimer ? <div className="error-message field-warning-color">{fieldErrors.disclaimer}</div> : null}
                            <div className="disclaimer-message" dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-disclaimer-message') }} />
                        </div>
                        <div className="pay-now-button-container">
                            <Button className={"pay-now-button common-style-primary-button"} onClick={payNow}>{getLanguageValue('register-individual.stage7-pay-now-button-text')} {isLoaderActive() && <>&nbsp;<Loader className={"loader-component"} /></>}</Button>
                        </div>
                        {fieldErrors.generalError ? <><br/><div className="error-message field-warning-color">{fieldErrors.generalError}</div></> : null}
                    </div>
                </div>
            </div>}
        </div>}
    </RegisterStage7Style>;

    function updateApplication() {
        dispatch({
            type: ACTIONS.ROUTER_SET_REDIRECT,
            payload: '/register-visa-for-individual/stage2'
        });
    }

    function viewApplication() {
        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/view');
        }, 10);
    }

    async function updateRejectedData() {

        let stateData = { };
        stateData.groupKey = null;
        stateData.formKey = props.individualKey;
        stateData.fields = {}; // currently nothing to fix
        stateData.files = {};
        for (let rejectReason in props.applicationRejectReasons) {
            let matchedFile = (lodashGet(props.registerIndividualStagesData, 'uploadedFiles') || []).find((uploadedItem => uploadedItem.fileType == rejectReason))
            if (matchedFile) {
                stateData.files[rejectReason] = matchedFile;
            }
            else {
                const stage = rejectReason.substring(0, 6);
                const fieldKey = rejectReason.substring(6, 7).toLowerCase() + rejectReason.substring(7);
                let fieldValue = lodashGet(props.registerIndividualStagesData, stage + 'Data.' + fieldKey);
                stateData.fields[rejectReason] = fieldValue;
            }
        }

        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [{
                path: 'rejectedApplicationData',
                value: stateData
            }]
        });
        // go to update rejected data page
        navigateToPath(dispatch, getContextRoot() + 'update-rejected-data');
    }

    function getStatus() {

        let fullCircleArrayCount = [];
        let emptyCircleArrayCount = [];
        let rejectWithReasonsFlag = false;
        if (Object.keys(props.applicationRejectReasons).length) {
            rejectWithReasonsFlag = true;
        }
        if (formErrors) {

            return <span className={"form-status contains-errors"}>{getLanguageValue('register-application-status-contains-errors')}</span>;
        }
        switch (statusX) {
            case 'paid':
                fullCircleArrayCount = [1,1];
                emptyCircleArrayCount = [1,1];
                break;
            case 'waiting-for-agent':
                fullCircleArrayCount = [1,1,1];
                emptyCircleArrayCount = [1];
                break;
            case 'ready':
            case 'ready-without-files':
                fullCircleArrayCount = [1,1,1,1];
                emptyCircleArrayCount = [];
                break;
            case 'reject':
                fullCircleArrayCount = [1,1,1];
                return <>
                <span className={"form-status " + statusX}>{getLanguageValue('register-application-status-' + statusX + (rejectWithReasonsFlag ? '-with-reasons' : ''))}</span>
                <br className={"status-br"}/>
                <span className={"progress-circles-container"}>
                        {fullCircleArrayCount.map((item, itemIndex) => (<FiberManualRecordIcon key={itemIndex} className={"circle-component"} />))}
                    <CancelIcon className={"reject-circle-component"} />
                    </span>
                </>;
            default:
                fullCircleArrayCount = [1];
                emptyCircleArrayCount = [1,1,1];
                break;
        }
        return <>
            <span className={"form-status " + statusX}>{getLanguageValue('register-application-status-' +statusX + (rejectWithReasonsFlag ? '-with-reasons' : ''))}</span>
            <br className={"status-br"}/>
            <span className={"progress-circles-container"}>
                    {fullCircleArrayCount.map((item, itemIndex) => (<FiberManualRecordIcon key={itemIndex} className={"circle-component"} />))}
                {emptyCircleArrayCount.map((item, itemIndex) => (<RadioButtonUncheckedIcon key={itemIndex} className={"circle-component"} />))}
                </span>
            </>;
    }

    function getVisaLinks() {
        let fileIndex = 1;
        return props.visaFilesIdArray.map((item, itemIndex) => <React.Fragment key={itemIndex}><a target={'_blank'} rel="noreferrer" href={getApiRoot() + 'download-visa/?type=individual&key=' + props.individualKey + '&fileId=' + item}>{getLanguageValue('register-common.download-filename')}{fileIndex++}</a><br/></React.Fragment>);
    }

    function getPrice(productType) {
        const prices = CONSTANTS.PRICES;
        switch (productType) {
            case 'visaType':
                switch (lodashGet(props.stage2Data, 'visaType')) {
                    case '30days-single':
                        return prices['30days_single'];
                    case '30days-multi':
                        return prices['30days_multi'];
                    case '90days-single':
                        return prices['90days_single'];
                    case '90days-multi':
                        return prices['90days_multi'];
                }
                break;
            case 'expedite':
                if (lodashGet(props.stage2Data, 'visaExpeditedFlag')) {
                    return prices['expedite'];
                }
                return 0;
            case 'deliveryType':
                switch (lodashGet(props.stage2Data, 'deliveryType')) {
                    case 'messenger-d-self-p':
                        return parseFloat(CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(props.stage2Data, 'deliveryDestination'))?.value);
                    case 'messenger-d-messenger-p':
                        return parseFloat(CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(props.stage2Data, 'deliveryDestination'))?.value) * 2;
                    case 'self-d-self-p':
                    default:
                        return 0;
                }
        }
        return 0;
    }

    function getApplicationsCount() {
        return 1;
    }

    function getTotalPrice() {
        return (getPrice('visaType') + getPrice('expedite') + getPrice('deliveryType')) * getApplicationsCount();
    }

    async function payNow() {
        // validate that the disclaimer is checked
        if (isLoaderActive()) {
            return;
        }
        if (!disclaimerChecked) {
            setFieldErrors({ disclaimer: getLanguageValue('register-field-errors-must-confirm') });
            return;
        }
        // test validation of form
        try {
            setFieldErrors({});
            setFormErrors(false);
            setLoaders({ ...loaders, validate: true });
            let recaptchaToken = await googleRecaptcha();
            let url = getApiRoot() + 'validate-individual-form/?languageKey=' + props.languageKey + '&stage=beforePay&noEmail=1&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    key: props.individualKey,
                    recaptchaToken
                })
            };
            let result = await (await fetch(url, options)).json();
            setLoaders({ ...loaders, validate: false });
            if (!lodashGet(result, 'data.success')) {
                if (Array.isArray(lodashGet(result, 'err'))) {
                    if (lodashGet(result, 'err').indexOf('field-arrivalDate') > -1) {
                        setFieldErrors({ generalError: getLanguageValue('register-individual.stage7-validation-error-generic') });
                        setFormErrors(true);
                    }
                    else if (lodashGet(result, 'err').indexOf('field-arrivalDateWithVisaType') > -1) {
                        // error with arrival date
                        setFieldErrors({ generalError: getLanguageValue('register-individual.stage7-validation-error-arrival-date') });
                        setFormErrors(true);
                    }
                }
                else {
                    setFieldErrors({ generalError: getLanguageValue('register-individual.stage7-validation-error-generic') });
                }
                return;
            }
            actions.nextStage();
        }
        catch (err) {
            setLoaders({ ...loaders, validate: false });
            setFieldErrors({ generalError: getLanguageValue('register-individual.stage7-validation-error-generic') });
        }
    }

    function isLoaderActive() {
        for (let loaderKey in loaders) {
            if (loaders[loaderKey]) {
                return true;
            }
        }
        return false;
    }
}

const RegisterStage7Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: 95%;
    }
  }
  .update-button, .view-button, .update-rejected-data-button {
    border: 1px solid black;
    height: 35px;
    width: 100%;
    white-space: nowrap;
  }
  .download-visa-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      min-width: 60px;
    }
  }
  .download-visa-files-title-text {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      color: green;
    }
  }
  .desktop-details-table {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
    width: 80%;
    min-width: 840px;
    border-collapse: collapse;
    border: 1px solid black;
    th {
      background-color: #1c3764;
      color: white;
      //border-left: 1px solid black;
      //border-bottom: 1px solid black;
      padding-top: 5px;
      padding-bottom: 5px;
      //margin: 0;
      border: 1px solid black;
    }
    td {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .no-padding {
      padding: 0;
    }
  }
  .mobile-details-table {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      display: none;
    }
    .row-item {
      display: flex;
      justify-content: space-between;
      &.odd {
        background-color: #ccc;
      }
      &.even {
        background-color: #eee;
      }
      .row-name-container {
        font-weight: bold;
        font-size: 16px;
      }
      .row-value-container {
        font-size: 13px;
      }
    }
    .buttons-container {
      flex-wrap: wrap;
      margin-top: 10px;
      > div {
        width: 48%;
      }
    }
    .view-button-container.full-width {
      width: 100%;
    }
    .update-rejected-data-container {
      margin-top: 10px;
      width: 100% !important;
    }
  }
  .progress-circles-container {
    white-space: nowrap;
  }
  .reject-circle-component {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .circle-component {
    color: #1460aa;
  }

  .status-br {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
  }
  .status-desktop-container.with-errors, .status-mobile-container.with-errors {
    background-color: rgba(242, 79, 79, 0.5);
  }
  .form-status {
    &.unpaid, &.reject, &.contains-errors {
      font-weight: bold;
      color: ${CSS_COLORS.FIELD_WARNING};
    }
    &.ready, &.paid, &.ready-without-files {
      font-weight: bold;
      color: green;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      position: relative;
      top: -7px;
      &.contains-errors {
        top: 0;
      }
    }
  }
  .ready-to-pay-and-payment-summary-container {
    vertical-align: top;
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
        width: 80%;
        min-width: 840px;
        > div {
          display: inline-block;
        }
    }
  }
  .ready-to-pay-container {
    vertical-align: top;
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 66%;
      padding-left: 1%;
    }
  }
  .payment-summary-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 33%;
    }
  }
  .payment-due-by-title {
    color: #045575;
    font-size: 20px;
  }
  .payment-due-by-date {
    font-size: 20px;
  }
  .payment-summary-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .header {
      font-weight: bold;
    }
  }
  .dollar-right {

  }
  .total-price {
    color: ${CSS_COLORS.FIELD_WARNING};
    min-width: 73px;
    html.ltr & {
      width: 97px;
    }
  }
  .disclaimer-component {
    html.rtl & {
      margin-right: -12px;
    }
  }
  .pay-now-button-container {
    margin-top: 10px;
  }
  .pay-now-button {
    width: 100%;
  }
  .loader-component {
    color: white;
  }
`;

const RegisterStage7 = connect(
    (state) => ({
        uploadedFiles: lodashGet(state, 'registerIndividualStagesData.uploadedFiles') || [],
        applicationStatus: lodashGet(state, 'registerIndividualStagesData.status') || 'unpaid',
        applicationRejectReasons: lodashGet(state, 'registerIndividualStagesData.rejectReasons') || {},
        visaFilesIdArray: lodashGet(state, 'registerIndividualStagesData.visaFilesIdArray') || [],
        languageKey: state.languageKey, // make everything re-render
        createDate: lodashGet(state, 'registerIndividualStagesData.createDate'),
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        registerIndividualStagesData: lodashGet(state, 'registerIndividualStagesData')
    }),
    {})(RegisterStage7Component);

export default RegisterStage7;
